import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { IncinerateEditService } from './incinerate-edit.service';
import { UOMHelper } from '../waste-type/waste-type-edit.service';

@Component({
	selector: 'app-incinerate-edit',
	templateUrl: './incinerate-edit.component.html'
})
export class IncinerateEditComponent extends iBisBaseComponent {
	@ViewChild('incinerateForm') incinerateForm: DxFormComponent;

	public edit: IncinerateEditService;
	public uomHelper: UOMHelper;
	private cboWasteType: any;

	constructor(public proxy: ProxyService) {
		super(proxy.incinerate, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if (this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});
	}

	// onShownIncinerate(ev: any) {
	// 	this.proxy.wasteType.eliminationCode.load().then(data => {
	// 		const ent = data.data.find((x: { code: any }) => x.code === 'D10');
	// 		if (ent) {
	// 			this.edit.model.eliminationId = ent.id;
	// 		}
	// 	}, (error: any) => this.proxy.lg.showError(error));
	// 	if (this.edit.model.wasteTypeId) {
	// 		this.proxy.wasteType.selectedId = this.edit.model.wasteTypeId;
	// 		// 	this.setWasteDetails(this.edit.model.wasteTypeId);
	// 	}
	// }

	onOpenStockPopup = (ev: any) => {
		this.proxy.actualStock.showPopup({
			edit: this.edit.model.id ? true : false,
			eliminationId: this.edit.model.id,
			wasteTypeId: this.edit.model.wasteTypeId,
			workCenterId: this.edit.model.workCenterId,
			dsSelected: this.edit.dsTreatmentSource
		}, true).then(data => {
			if (data && data.isSaving) {
				this.edit.model.quantity = data.totalQuantity;
				this.edit.model.wasteTypeId = data.wasteTypeId;
				this.edit.dsTreatmentSource = data.dsSource;
				this.proxy.wasteType.selectedId = data.wasteTypeId;
			}
		}, (error: any) => this.proxy.lg.showError(error));
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onWasteTypeChanged = (ev: any) => {
		this.proxy.wasteType.selectedId = ev.value;
	};

	// because wasteType is a textBox instead of selectBox we work directly
	// with wasteType.code instead of his id
	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
	};

	onWastecodeChangedEv = (ev: any) => {
		if (ev.value === '' && (this.edit.model.id === undefined || this.edit.model.id === null)) {
			this.edit.model.wasteTypeId = null;
			this.proxy.wasteType.selectedId = this.edit.model.wasteTypeId;
			this.edit.dsTreatmentSource = [];
			this.edit.model.quantity = 0;
		}
	};

	onFocusEv = () => {
		if (this.edit.type === '0') {
			this.incinerateForm.instance.focus();
		}
	};
}
