import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { ProxyService } from '../../../service/proxy.service';
import { UOMHelper } from '../../waste-type/waste-type-edit.service';
import { TransportFullEditService } from './transport-full-edit.service';

@Component({
	selector: 'app-transport-full-edit',
	templateUrl: './transport-full-edit.component.html'
})
export class TransportFullEditComponent extends iBisBaseComponent {
	@ViewChild('fullTransportForm') form: DxFormComponent;
	public edit: TransportFullEditService;
	public uomHelper: UOMHelper;

	public noFatQtyCollected: string;

	private cboFAT: any;
	private cboCar: any;
	private cboDriver: any;
	private cboWasteType: any;

	constructor(public proxy: ProxyService) {
		super(proxy.transportFull, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if(this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});

		proxy.transporter.onDataChanged.subscribe((data) => {
			if (data === 'selected' && this.cboCar && this.cboDriver) {
				this.proxy.resetValidation(this.cboCar);
				this.proxy.resetValidation(this.cboDriver);
			}
		});
		proxy.transportApproval.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboFAT) {
				this.proxy.resetValidation(this.cboFAT);
			}
			if (data === 'selected') {
				this.edit.model.fat = this.proxy.transportApproval.selected;
			}
		});
	}

	onFATInit = (ev: any) => {
		this.cboFAT = ev.component;
	};

	onCarInit = (ev: any) => {
		this.cboCar = ev.component;
	};

	onDriverInit = (ev: any) => {
		this.cboDriver = ev.component;
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onFatChanged = (ev: any) => {
		this.proxy.transportApproval.selectedId = ev.value;
	};

	onDestinationChanged = (ev: any) => {
		this.proxy.transportApproval.destinationId = ev.value;
	};

	onTransporterChanged = (ev: any) => {
		this.proxy.transporter.selectedId = ev.value;
		this.proxy.transportApproval.transporterId = ev.value;
	};

	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
		this.proxy.transportApproval.generatorId = ev.value;
	};

	onFormInit(ev: any) {
		this.form = ev.component;
	}

	onFocusIn = () => {
		this.form.instance.focus();
	};

	onOpenStockPopup = () => {
		this.proxy.actualStock.showPopup({
			edit: this.edit.model.id ? true : false,
			fullTransportId: this.edit.model.id,
			wasteTypeId: this.edit.model.wasteTypeId,
			workCenterId: this.edit.model.workCenterId,
			dsSelected: this.edit.dsTransportSource
		}, true).then(data => {
			if (data && data.isSaving) {
				this.edit.model.quantity = data.totalQuantity;
				this.edit.model.wasteTypeId = data.wasteTypeId;
				this.edit.dsTransportSource = data.dsSource;
				this.proxy.wasteType.selectedId = data.wasteTypeId;
			}
		}, error => this.proxy.lg.showError(error));
	};

	public get qtyHelpText(): string {
		return this.proxy.transportApproval.collectNoFAT ?
			this.L('FAT_QUANTITY_HELP', [this.proxy.transportApproval.collectNoFAT.toString(), this.proxy.wasteType.selectedUomName]) : '';
	}

	quantityValidation = () => {
		if(this.edit.model.approvalId){
			return this.proxy.transportApproval.selected.quantityLeft >= this.edit.model.quantity;
		}
		return true;
	};

	validateFAT = (options: any) => {
		if (this.edit.model.wasteType.hazard === 1 && this.edit.model.quantity >= 1000 && !this.edit.model.approvalId) {
			return false;
		} else if ((this.proxy.transportApproval.collectNoFAT + this.edit.model.quantity) >= 1000 &&
			this.edit.model.wasteType.hazard === 1 && !this.edit.model.approvalId) {
			return false;
		} else {
			return true;
		}
	};
}
