import { Component, ViewChild } from '@angular/core';
import { iBisBaseComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../service/proxy.service';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { TreatmentEditService } from './treatment-edit.service';
import { UOMHelper } from '../waste-type/waste-type-edit.service';
import { ActualStockEditService } from '../stock/actual-stock/actual-stock-edit.service';

@Component({
	selector: 'app-treatment-edit',
	templateUrl: './treatment-edit.component.html'
})
export class TreatmentEditComponent extends iBisBaseComponent {
	@ViewChild('treatmentForm') treatmentForm: DxFormComponent;
	public edit: TreatmentEditService;
	public uomHelper: UOMHelper;
	public dsWasteType: any;
	private cboWasteType: any;

	constructor(public proxy: ProxyService, public editS: ActualStockEditService) {
		super(proxy.treatment, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.uomHelper = new UOMHelper(this.proxy.wasteType);

		proxy.wasteType.onDataChanged.subscribe((data) => {
			if (data === 'code' && this.cboWasteType) {
				this.proxy.resetValidation(this.cboWasteType);
			}
			if (data === 'selected') {
				this.edit.model.wasteType = this.proxy.wasteType.selected;
				if(this.edit.model.uom) {
					this.proxy.wasteType.selected.uom = this.edit.model.uom;
					this.uomHelper.update(this.proxy.wasteType.selectedUomName);
				}
			}
		});
	}

	onWorkCenterChanged = (ev: any) => {
		this.proxy.wasteType.workCenterId = ev.value;
	};

	onWasteTypeInit = (ev: any) => {
		this.cboWasteType = ev.component;
	};

	onFocusEv = () => {
		this.treatmentForm.instance.focus();
	};

	onOpenStockPopup = () => {
		this.editS.showPopup({
			edit: this.edit.model.id ? true : false,
			treatmentId: this.edit.model.id,
			dsSelected: this.edit.source,
			wasteTypeId: this.edit.model.wasteTypeId,
			workCenterId: this.edit.model.workCenterId
		}, true).then(data => {
			if (data && data.isSaving) {
				this.edit.source = data.dsSource;
				this.edit.model.quantity = data.totalQuantity;
				if(this.edit.model.wasteTypeId !== data.wasteTypeId){
					this.edit.model.wasteTypeId = data.wasteTypeId;
					this.proxy.wasteType.selectedId = data.wasteTypeId;
					this.edit.item = [];
					this.edit.model.totalQty = 0;
				}
			}
		}, error => this.proxy.lg.showError(error));
	};

	onItemToolbarPreparing(ev: any) {
		ev.toolbarOptions.items.unshift({
			template: 'treatmentTemplate',
			location: 'before'
		});
	}

	onItemInserted(ev: any) {
		this.edit.model.totalQty = Math.round((this.edit.model.totalQty + ev.data.quantity) * Math.pow(10, 6)) / Math.pow(10, 6);
	}

	onItemRemoving(ev: any) {
		if (ev.data) {
			if (this.edit.model.id) {
				this.edit.onRemoveItem(ev.data.id);
			} else {
				const indx = this.edit.item.indexOf(ev.data);
				this.edit.item.splice(indx, 1);
			}
			this.edit.model.totalQty = Math.round((this.edit.model.totalQty - ev.data.quantity) * Math.pow(10, 6)) / Math.pow(10, 6);
		}
	}

	onQuantityUpdating(ev: any) {
		if (ev.newData.quantity < 0) {
			ev.cancel = true;
			return;
		}

		this.edit.model.totalQty = Math.round((this.edit.model.totalQty - ev.oldData.quantity + ev.newData.quantity)
			* Math.pow(10, 6)) / Math.pow(10, 6);
	}

	onWastecodeChanged = (ev: any) => {
		if (ev.value === '' && (this.edit.model.id === undefined || this.edit.model.id === null)) {
			this.edit.item = [];
			this.editS.dsStock = [];
			this.edit.model.quantity = 0;
			this.edit.model.totalQty = 0;
			this.edit.model.wasteTypeId = null;
			this.proxy.wasteType.selectedId = null;
		}
	};
}
