import notify from 'devextreme/ui/notify';
import { Component, ViewChild } from '@angular/core';
import { iBisViewComponent } from '@dohu/ibis-common';
import { ProxyService } from '../../../service/proxy.service';
import { ActualStockEditService } from './actual-stock-edit.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';

@Component({
	selector: 'app-actual-stock-edit',
	templateUrl: './actual-stock-edit.component.html'
})
export class ActualStockEditComponent extends iBisViewComponent {

	@ViewChild('gridActualStockSelect') grid: DxDataGridComponent;
	public edit: ActualStockEditService;

	constructor(public proxy: ProxyService) {
		super(proxy.actualStock, proxy.lg, proxy.iui, proxy.activatedRoute);
		this.key = 'actualStockSelect';
	}

	public toolbarPreparing(event: any, showAdd?: boolean, customStore?: boolean): void {
		super.toolbarPreparing(event, showAdd, customStore);
		event.toolbarOptions.items.unshift({
			template: 'totalQuantity',
			location: 'center'
		});
		event.toolbarOptions.items.unshift({
			template: 'wasteCode',
			location: 'before'
		});
		event.toolbarOptions.items.unshift({
			text: this.L('WASTE_CODE') + ': ',
			location: 'before'
		});
	}

	onSave = (ev: any) => {
		this.grid.instance.saveEditData().then(() => {
			const selData = this.grid.instance.getSelectedRowsData();
			this.edit.dsSource = [];
			for (const s of selData) {
				this.edit.dsSource.push({ id: s.id, type: s.typeId, quantity: s.quantity, remain: s.remain });
			}
			this.edit.onSaveClose(ev);
		}, error => this.proxy.lg.showError(error));
	};

	onStockUpdating(ev: any) {
		if ((ev.newData.quantity > ev.oldData.remain) || (ev.newData.quantity < 0)) {
			ev.cancel = true;
			return;
		}
		setTimeout(() => {
			const selData = ev.component.getSelectedRowsData();
			this.edit.model.totalQuantity = 0;
			for (const s of selData) {
				this.edit.model.totalQuantity += s.quantity;
			}
			this.edit.model.totalQuantity = Math.round(this.edit.model.totalQuantity * Math.pow(10, 6)) / Math.pow(10, 6);
		}, 0);
	}

	onEditingStart = (ev: any) => {
		const keyExist = ev.component.getSelectedRowKeys().find((x: any) => x === ev.key);
		if (!keyExist) {
			ev.cancel = true;
			notify('Selectați linia înainte de a edita!', 'error', 3000);
		}
	};

	onSelectionChange(ev: any) {
		if (ev.currentSelectedRowKeys?.length > 0) {
			for (const key of ev.currentSelectedRowKeys) {
				const row = this.edit.dsStock.find((x: { id: any }) => x.id === key);
				if (row) {
					row.quantity = row.quantity === 0 ? row.remain : row.quantity;
					this.edit.model.totalQuantity += row.quantity;
				}
			}
		}
		if (ev.currentDeselectedRowKeys && ev.currentDeselectedRowKeys.length > 0) {
			for (const key of ev.currentDeselectedRowKeys) {
				const row = this.edit.dsStock.find(x => x.id === key);
				if (row) {
					this.edit.model.totalQuantity -= row.quantity;
				}
			}
		}
		this.edit.model.totalQuantity = Math.round(this.edit.model.totalQuantity * Math.pow(10, 6)) / Math.pow(10, 6);
	}

	onStockShown(ev: any) {
		if (!this.edit.model.wasteTypeId) {
			this.edit.dsSource = [];
		}
		if (this.grid.instance) {
			if (this.grid.instance.getSelectedRowKeys().length > 0) {
				this.grid.instance.deselectAll();
				this.edit.model.totalQuantity = 0;
			}
			this.grid.instance.selectRows(this.edit.selectedKeys, false);
		}
	}

	loadStockDataSource(ev: any) {
		if (ev.event && ev.value) {
			this.edit.getActualStock(ev.value, this.edit.model.workCenterId).load().then((dd: any) => {
				if (dd) {
					for (const i of dd) {
						i.quantity = 0;
						i.totalRemain = i.remain;
					}
					this.edit.dsStock = dd;
					this.edit.model.dsSource = [];
					this.edit.model.totalQuantity = 0;
					this.edit.model.uom = this.proxy.wasteType.uom.find(h => h.id === dd[0].wasteUom).value;
				}
			}, error => this.proxy.lg.showError(error));
		}
	}
}
