export const environment = {
	production: true,
	deployId: 'e29053d6-652b-4c8c-b4c6-0243281b77e3',
	defaultUrlServer: 'https://api-aws.sistemis.ro/qhse/',
	authServer: 'https://api-aws.sistemis.ro/auth/',
	saasServer: 'https://api-aws.sistemis.ro/saas/',
	filePath: 'https://api-test.sistemis.ro/qhse_file/',
	fileUploadPath: 'http://52.58.46.6:8070/saas/',
	termCond: 'https://www.evidentadeseuri.ro/termeni-si-conditii/',
	manualUrl: 'https://www.evidentadeseuri.ro/manual-de-utilizare/',
	allowRegister: true,
	app: {link: 'https://www.evidentadeseuri.ro/', text: 'EvidentaDeseuri.ro' }
};
