import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../../service/data.service';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';

@Injectable({
	providedIn: 'root'
})
export class ActualStockEditService extends iBisEditService {
	public data: CustomStore;
	public type: { id: number; value: string }[];
	public wasteType!: CustomStore;

	dsStock: any;
	dsSource: any;
	dsTreatmentItem: any;
	selectedKeys: any = [];

	constructor(private entity: iBisEntityService, private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.title = 'ACTUAL_STOCK';
		this.validation = null;
		this.data = this.getData();
		this.type = this.getType();
	}

	public getActualStock(wasteTypeId: string, workCenterId: string): CustomStore {
		const q = new EntityQuery('ActualStock');
		q.linkEq('wasteCode', 'code', 'WasteType', 'id', wasteTypeId).gt('remain', 0);
		q.linkEq('currentWorkCenter', 'name', 'Party', 'id', workCenterId);
		return this.entity.store(q.addOrderByDesc(['date']), false, 'id');
	}

	public override showPopup(data: any, byId?: boolean, serverUrl?: string): Promise<any> {

		this.wasteType = this.getWasteTypeFromStock(data.workCenterId, data.wasteTypeId);

		return super.showPopup(data, byId, serverUrl);
	}

	getWasteTypeFromStock(workCenterId: string, existing: string = null, wasteTypeTypeId: string = null): CustomStore {
		const q = new EntityQuery('WasteType').neq('parentId', '00000000-0000-0000-0000-000000000000')
			.addOrderBy(['code']);
		q.distinct = true;
		q.linkEq('id', 'wasteTypeId', 'WasteTypeParty', 'workCenterId', workCenterId);
		q.link('code', 'wasteCode', new EntityQuery('ActualStock').gt('remain', 0));
		if (wasteTypeTypeId != null && wasteTypeTypeId !== '') {
			q.eq('typeId', wasteTypeTypeId);
		}
		q.fields.push('id', 'code', 'name', 'hazard', 'uom');
		if (existing) {
			const qExist = new EntityQuery('WasteType').eq('id', existing);
			qExist.fields.push('id', 'code', 'name', 'hazard', 'uom');
			q.union(qExist);
		}
		return this.entity.store(q, false, 'id');
	}

	createDefault() {
		this.dsStock = [];
		return { totalQuantity: 0 };
	}

	getById(data: any): Promise<any> {
		this.selectedKeys = [];
		if (!data.edit) { // on inserting
			if (!data.wasteTypeId && data.dsSelected.length === 0) { // no data selected
				return this.getInsertWithNoElementsSelected(data);
			} else { // data selected
				return this.getInsertWithElementsSelected(data);
			}
		} else { // On update
			if (data.dsSelected?.length === 0) { // no data selected
				if (data.eliminationId) {
					return this.editIncinerationWithNoElementsSelected(data);
				} else if (data.fullTransportId) {
					return this.editFullTransportWithNoElementsSelected(data);
				}
			} else { // with data selected
				if (data.eliminationId) {
					return this.editIncinerationWithElementsSelected(data);
				} else if (data.fullTransportId) {
					return this.editFullTransportWithElementsSelected(data);
				}
			}
		}
	}

	onRemove(id: string): Promise<void> {
		return new Promise((resolve) => {
			resolve();
		});
	}

	onSaveEv(): Promise<any> {
		return new Promise((resolve) => {
			this.model.totalQuantity = Math.round(this.model.totalQuantity * Math.pow(10, 6)) / Math.pow(10, 6);
			this.model.dsSource = this.dsSource;
			this.model.isSaving = true;
			resolve(this.model);
		});
	}

	reset(): void {
		this.dsStock = [];
		this.model = this.createDefault();
	}

	getInsertWithNoElementsSelected(data: any): Promise<any> {
		return new Promise<any>((resolve) => {
			if (!data.wasteTypeId && data.dsSelected.length === 0) { // no data selected
				this.dsStock = [];
				this.dsSource = [];
				resolve({ workCenterId: data.workCenterId, wasteTypeId: data.wasteTypeId, totalQuantity: 0, edit: data.edit });
			}
		});
	}

	getInsertWithElementsSelected(data: any): Promise<any> {
		return new Promise<any>((resolve) => {
			this.getActualStock(data.wasteTypeId, data.workCenterId).load().then((dd: any) => {
				if (dd) {
					data.dsSelected.forEach(element => {
						const i = dd.findIndex(x => x.id === element.id);
						if (i > -1) {
							dd[i].quantity = element.quantity;
						}
						this.selectedKeys.push(element.id);
					});
				}
				let index = dd.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
				while (index > -1) {
					dd[index].quantity = 0;
					index = dd.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
				}
				this.dsStock = dd;
				this.model.dsSource = [];
				resolve({
					workCenterId: data.workCenterId,
					wasteTypeId: data.wasteTypeId,
					totalQuantity: 0,
					edit: data.edit
				});
			}, error => this.lg.showError(error));
		});
	}

	editIncinerationWithNoElementsSelected(data: any): Promise<any> {
		return new Promise<any>((resolve) => {
			this.getActualStock(data.wasteTypeId, data.workCenterId).load().then((actualStock: any) => {
				if (actualStock) {
					const q = new EntityQuery('Elimination').eq('id', data.eliminationId);
					q.fields.push('id', 'observations');
					const qq = new EntityQuery('EliminationSource');
					qq.fields.push('id', 'quantity', 'eliminationId', 'generationId', 'collectionId', 'treatmentItemId');
					this.entity.query(q.link('id', 'eliminationId', qq)).then((incinerations: any) => {

						const incinerationsArray: any = [];
						const incinerationsQuantities: any = [];
						incinerations.forEach(incineration => {
							const id = incineration.f5 != null ? incineration.f5 :
								(incineration.f6 != null ? incineration.f6 : incineration.f7);
							const quantity = incineration.f3;
							incinerationsQuantities.push({ id: id, quantity: quantity });
							incinerationsArray.push(id);
						});

						let index: number;
						this.getIncineratedStock(incinerationsArray).load().then((incinerationStock: any) => {
							if (incinerationStock.length > 0) {
								incinerationsQuantities.forEach((element: { id: any; quantity: any }) => {
									index = incinerationStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										incinerationStock[index].quantity = element.quantity;
										incinerationStock[index].remain += element.quantity;
									}
								});
								incinerationStock.forEach((element: { id: any; quantity: any }) => {
									index = actualStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										actualStock[index].quantity = element.quantity;
										actualStock[index].remain += element.quantity;
									} else {
										actualStock.push(element);
									}
									this.selectedKeys.push(element.id);
								});
							}
							index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							while (index > -1) {
								actualStock[index].quantity = 0;
								index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							}
							this.dsStock = actualStock;
							this.model.dsSource = [];
							resolve({
								workCenterId: data.workCenterId,
								wasteTypeId: data.wasteTypeId,
								totalQuantity: 0,
								edit: data.edit
							});

						}, error => this.lg.showError(error));

					}, error => this.lg.showError(error));
				}
			}, error => this.lg.showError(error));
		});
	}

	editFullTransportWithNoElementsSelected(data: any) {
		return new Promise<any>((resolve) => {
			this.getActualStock(data.wasteTypeId, data.workCenterId).load().then((actualStock: any) => {
				if (actualStock) {
					const q = new EntityQuery('Transport').eq('id', data.fullTransportId);
					q.fields.push('id', 'observations');
					const qq = new EntityQuery('TransportSource');
					qq.fields.push('id', 'quantity', 'transportId', 'generationId', 'collectionId', 'treatmentItemId');
					this.entity.query(q.link('id', 'transportId', qq)).then((transports: any) => {

						const transportsArray: any = [];
						const transportsQuantities: any = [];
						transports.forEach(transport => {
							const id = transport.f5 != null ? transport.f5 :
								(transport.f6 != null ? transport.f6 : transport.f7);
							const quantity = transport.f3;
							transportsQuantities.push({ id: id, quantity: quantity });
							transportsArray.push(id);
						});
						let index: number;
						this.getIncineratedStock(transportsArray).load().then((transportStock: any) => {
							if (transportStock.length > 0) {
								transportsQuantities.forEach((element: { id: any; quantity: any }) => {
									index = transportStock.findIndex(x => x.id === element.id);
									if (index > -1) {
										transportStock[index].quantity = element.quantity;
										transportStock[index].remain += element.quantity;
									}
								});
								transportStock.forEach((element: { id: any; quantity: any }) => {
									index = actualStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										actualStock[index].quantity = element.quantity;
										actualStock[index].remain += element.quantity;
									} else {
										actualStock.push(element);
									}
									this.selectedKeys.push(element.id);
								});
							}
							index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							while (index > -1) {
								actualStock[index].quantity = 0;
								index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							}
							this.dsStock = actualStock;
							this.model.dsSource = [];
							resolve({
								workCenterId: data.workCenterId,
								wasteTypeId: data.wasteTypeId,
								totalQuantity: 0,
								edit: data.edit
							});
						}, error => this.lg.showError(error));
					}, error => this.lg.showError(error));
				}
			}, error => this.lg.showError(error));
		});
	}

	editIncinerationWithElementsSelected(data: any) {
		return new Promise<any>((resolve) => {
			this.getActualStock(data.wasteTypeId, data.workCenterId).load().then((actualStock: any) => {
				if (actualStock) {
					const q = new EntityQuery('Elimination').eq('id', data.eliminationId);
					q.fields.push('id', 'observations');
					const qq = new EntityQuery('EliminationSource');
					qq.fields.push('id', 'quantity', 'eliminationId', 'generationId', 'collectionId', 'treatmentItemId');
					this.entity.query(q.link('id', 'eliminationId', qq)).then((incinerations: any) => {

						const incinerationsArray: any = [];
						const incinerationsQuantities: any = [];
						incinerations.forEach(incineration => {
							const id = incineration.f5 != null ? incineration.f5 :
								(incineration.f6 != null ? incineration.f6 : incineration.f7);
							const quantity = incineration.f3;
							incinerationsQuantities.push({ id: id, quantity: quantity });
							incinerationsArray.push(id);
						});
						let index: number;
						this.getIncineratedStock(incinerationsArray).load().then((incinerationStock: any) => {
							if (incinerationStock.length > 0) {
								incinerationsQuantities.forEach((element: { id: any; quantity: any }) => {
									index = incinerationStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										incinerationStock[index].quantity = element.quantity;
										incinerationStock[index].remain += element.quantity;
									}
								});
								incinerationStock.forEach((element: { id: any; quantity: any; remain: any }) => {
									index = actualStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										actualStock[index].quantity = element.quantity;
										actualStock[index].remain = element.remain;
									} else {
										actualStock.push(element);
									}
								});
							}
							actualStock.forEach((element: { id: any; quantity: number }) => {
								index = data.dsSelected.findIndex((x: { id: any }) => x.id === element.id);
								if (index > -1) {
									element.quantity = data.dsSelected[index].quantity;
									this.selectedKeys.push(element.id);
								} else {
									element.quantity = 0;
								}
							});
							index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							while (index > -1) {
								actualStock[index].quantity = 0;
								index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							}
							this.dsStock = actualStock;
							this.model.dsSource = [];
							resolve({
								workCenterId: data.workCenterId,
								wasteTypeId: data.wasteTypeId,
								totalQuantity: 0,
								edit: data.edit
							});
						}, error => this.lg.showError(error));
					}, error => this.lg.showError(error));
				}
			}, error => this.lg.showError(error));
		});
	}

	editFullTransportWithElementsSelected(data: any) {
		return new Promise<any>((resolve) => {
			this.getActualStock(data.wasteTypeId, data.workCenterId).load().then((actualStock: any) => {
				if (actualStock) {
					const q = new EntityQuery('Transport').eq('id', data.fullTransportId);
					q.fields.push('id', 'observations');
					const qq = new EntityQuery('TransportSource');
					qq.fields.push('id', 'quantity', 'transportId', 'generationId', 'collectionId', 'treatmentItemId');
					this.entity.query(q.link('id', 'transportId', qq)).then((transports: any) => {

						const transportsArray: any = [];
						const transportsQuantities: any = [];
						transports.forEach(incineration => {
							const id = incineration.f5 != null ? incineration.f5 :
								(incineration.f6 != null ? incineration.f6 : incineration.f7);
							const quantity = incineration.f3;
							transportsQuantities.push({ id: id, quantity: quantity });
							transportsArray.push(id);
						});
						let index: number;
						this.getIncineratedStock(transportsArray).load().then((transportStock: any) => {
							if (transportStock.length > 0) {
								transportsQuantities.forEach((element: { id: any; quantity: any }) => {
									index = transportStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										transportStock[index].quantity = element.quantity;
										transportStock[index].remain += element.quantity;
									}
								});
								transportStock.forEach(element => {
									index = actualStock.findIndex((x: { id: any }) => x.id === element.id);
									if (index > -1) {
										actualStock[index].quantity = element.quantity;
										actualStock[index].remain = element.remain;
									} else {
										actualStock.push(element);
									}
								});
							}
							actualStock.forEach(element => {
								index = data.dsSelected.findIndex((x: { id: any }) => x.id === element.id);
								if (index > -1) {
									element.quantity = data.dsSelected[index].quantity;
									this.selectedKeys.push(element.id);
								} else {
									element.quantity = 0;
								}
							});
							index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							while (index > -1) {
								actualStock[index].quantity = 0;
								index = actualStock.findIndex((x: { quantity: any }) => x.quantity == null || undefined);
							}
							this.dsStock = actualStock;
							this.model.dsSource = [];
							resolve({
								workCenterId: data.workCenterId,
								wasteTypeId: data.wasteTypeId,
								totalQuantity: 0,
								edit: data.edit
							});
						}, error => this.lg.showError(error));
					}, error => this.lg.showError(error));
				}
			}, error => this.lg.showError(error));
		});
	}

	getIncineratedStock(ids: string[]): CustomStore {
		const q = new EntityQuery('ActualStock').in('id', ids);
		return this.entity.store(q, false, 'id');
	}

	private getData(): CustomStore {
		const q = new EntityQuery('ActualStock').addOrderBy(['date']);
		return this.entity.store(q, false, 'id');
	}

	private getType(): { id: number; value: string }[] {
		const list = [{ 'id': 0, 'value': 'GENERATE' }, { 'id': 1, 'value': 'COLLECT' }, { 'id': 2, 'value': 'TREATMENT' }];
		return this.ds.getEnum('actualStockType', list);
	}
}
