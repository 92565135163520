import { Injectable } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from '../../service/data.service';
import { EntityQuery, iBisEntityService } from '@dohu/ibis-entity';
import { iBisEditService, iBisLanguageService } from '@dohu/ibis-common';
import { WasteTypeEditService } from '../waste-type/waste-type-edit.service';

@Injectable({
	providedIn: 'root'
})
export class IncinerateEditService extends iBisEditService {
	public data: CustomStore;

	public deviceLine: string[];
	public dsTreatmentSource: any;

	private tp: any;
	private as = true;

	constructor(private entity: iBisEntityService, private wtp: WasteTypeEditService,
		private ds: DataService, lg: iBisLanguageService) {
		super(lg);
		this.setTitle();
		this.validation = 'incinerateValidation';

		this.dsTreatmentSource = [];
		this.deviceLine = this.getDeviceLine();
	}

	createDefault(): any {
		const result = { isValid: true, date: new Date(), deviceId: null, entityId: null, workCenterId: null };
		this.dsTreatmentSource = [];
		if (this.ds && this.ds.defaultParty) {
			result.deviceId = this.deviceLine[0];
			result.workCenterId = this.ds.defaultParty.wc;
		}
		return result;
	}

	getById(id: any): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.entity.getById('Elimination', id).then((data) => {
				if (!data) {
					reject();
				} else {
					this.dsTreatmentSource = [];
					this.entity.load(new EntityQuery('EliminationSource').eq('eliminationId', id)).then((ts) => {
						if (ts) {
							const s = [];
							ts.forEach(treatmentSource => {
								s.push({
									id: treatmentSource.generationId ?? (treatmentSource.collectionId ?
										treatmentSource.collectionId : treatmentSource.treatmentItem),
									quantity: treatmentSource.quantity,
									type: treatmentSource.generationId ? 0 : (treatmentSource.collectionId ? 1 : 2)
								});
							});
							data.sources = s;
						}
						this.wtp.selectedId = data.wasteTypeId;
						resolve(data);
					});
					// data.entityId = data.wc_parentId;
					// data.uom = DataService.getUOM(data.wt_uom);
					// data.wasteCode = data.wt_code;
					// data.wasteName = data.wt_name;
					// data.hazardName = DataService.getHazardName(data.wt_hazard);
				}
			}, (error: any) => this.lg.showError(error));
		});
	}

	onRemove(id: string): Promise<void> {
		return this.entity.remove('Elimination', id);
	}

	onSaveEv(): Promise<any> {
		if (this.dsTreatmentSource.length > 0) {
			this.model.sources = this.dsTreatmentSource;
		}
		this.model.uom = this.wtp.selected.uom;
		this.model.type = this.tp;
		if (!this.model.id) {
			const query = this.tp === '0' ? this.wtp.eliminationCode : this.wtp.capitalizationCode;
			return query.load().then((data: any) => {
				const code = this.tp === '0' ? 'D10' : 'R10';
				const ent = data.find((x: { code: any }) => x.code === code);
				if (ent) {
					if (this.tp === '0') {
						this.model.eliminationId = ent.id;
					} else {
						this.model.capitalizationId = ent.id;
					}
				}
				this.entity.save('Elimination', this.model.id, this.model);
			});
		} else {
			return this.entity.save('Elimination', this.model.id, this.model);
		}
	}

	reset(): void {
		this.model = this.createDefault();
	}

	public set actualStock(as: boolean) {
		this.as = as;
	}

	public get actualStock() {
		return this.as;
	}

	public set type(tp: any) {
		this.tp = tp;
		this.setTitle();
		this.data = this.getData();
		this.deviceLine = this.getDeviceLine();
	}

	public get type() {
		return this.tp;
	}

	private getData(): CustomStore {
		const q = new EntityQuery('EliminationView').eq('e.type', this.tp).addOrderByDesc(['e.date']);
		return this.entity.store(q, false, 'e_id');
	}

	private getDeviceLine() {
		return this.tp === '0' ? ['HP1250', 'HP1500'] : ['EAF'];
	}

	private setTitle() {
		this.title = this.tp === '0' ? 'INCINERATE' : 'RECYCLE';
		this.repaintPopup();
	}

	private repaintPopup() {
		if (this.popup) {
			this.popup.repaint();
		}
	}
}
